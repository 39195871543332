export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://socialclimb.id/',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/ridwanokimmm',
	},
	{
		id: 3,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/erputramadhan',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/erputramadhan',
	},
];
