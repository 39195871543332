// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'SMM Panel Social Climb',
		category: 'Web Application',
		projectView: 'ProjectSocialClimb',
		img: require('@/assets/images/socialclimb/landing.jpg'),
	}
];

export default projects;
